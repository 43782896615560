.badge {
  background: ffffff;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.5);
  border-radius: 8px 8px 8px 8px;
  overflow: hidden;
  height: 380px;
}
.header {
  padding: 0.5rem 0;
  height: 80px;
  background: #1b1b25;
  display: flex;
  justify-content: center;
}
.section-name {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 0;
}
.section-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0;
  background: #dedede;
}
.avatar {
  border-radius: 50%;
  marging-right: 1rem;
  width: 120px;
  height: 120px;
}
.footer {
  height: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #98ca3f;
  font-weight: bold;
  font-style: italic;
}
