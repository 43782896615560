.Navbar {
  width: 100%;
  padding: 0.5rem 0;
  background-color: #1c3643;
}
.brand {
  color: #fff;
  display: flex;
  align-items: center;
}
.brand:hover {
  color: #fff;
  text-decoration: none;
}
.logo {
  margin-right: 0.5rem;
}
