@import url(https://fonts.googleapis.com/css?family=Lato:300,400,700);
@import url(https://fonts.googleapis.com/css?family=Anton);
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-family: 'Lato', 'Helvetica Neue', sans-serif;
  background-color: #F4F4F7;
}

h1 {
  font-family: 'Anton', sans-serif;
}

a.link-unstyled {
  color: inherit;
}

a.link-unstyled:hover {
  color: inherit;
  text-decoration: none;
}

.btn {
  padding: 0.375rem 2rem;
}

.btn.btn-primary {
  color: #fff;
  background-color: #7DCD40;
  border-color: #7DCD40;
}

.btn.btn-primary:not(:disabled):not(.disabled):hover {
  color: #fff;
  background-color: #7DCD40;
  border-color: #7DCD40;
}

.btn.btn-primary:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #7DCD40;
  border-color: #7DCD40;
}

.btn.btn-primary:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(125, 205, 64, 0.5);
}

.btn.btn-danger {
  color: #fff;
  background-color: #CD4040;
  border-color: #CD4040;
}

.btn.btn-danger:not(:disabled):not(.disabled):hover {
  color: #fff;
  background-color: #CD4040;
  border-color: #CD4040;
}

.btn.btn-danger:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #CD4040;
  border-color: #CD4040;
}

.btn.btn-danger:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 64, 64, 0.5);
}
.badge {
  background: ffffff;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.5);
  border-radius: 8px 8px 8px 8px;
  overflow: hidden;
  height: 380px;
}
.header {
  padding: 0.5rem 0;
  height: 80px;
  background: #1b1b25;
  display: flex;
  justify-content: center;
}
.section-name {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 0;
}
.section-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0;
  background: #dedede;
}
.avatar {
  border-radius: 50%;
  marging-right: 1rem;
  width: 120px;
  height: 120px;
}
.footer {
  height: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #98ca3f;
  font-weight: bold;
  font-style: italic;
}

.Navbar {
  width: 100%;
  padding: 0.5rem 0;
  background-color: #1c3643;
}
.brand {
  color: #fff;
  display: flex;
  align-items: center;
}
.brand:hover {
  color: #fff;
  text-decoration: none;
}
.logo {
  margin-right: 0.5rem;
}

.hero2 {
  width: 100%;
  padding: 2rem 0;
  background: url(/static/media/stars.1d17360a.svg), #1b1b25;
  background-repeat: repeat;
  margin-bottom: 1rem;
  color: #fff;
  display: flex;
align-content: center;
justify-content: center;
}


.img-fluid{
	max-height :100px;

}
.form-grouph{
	
 display: none;
}
.PageLoading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
}
.Badges__hero {
 width: 100%;
  padding: 2rem 0;
  background: url(/static/media/stars.1d17360a.svg), #1B1B25;
  background-repeat: repeat;
  margin-bottom: 1rem;
  color: #ffffff;
}

.Badges__hero>.Badges__container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Badges__container {
  width: 100%;
  max-width: 580px;
  margin: 0 auto;
  padding: 0 1rem;
}

.Badges_conf-logo {
  margin-bottom: 2rem;
}

.Badges__buttons {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
}
.listcontainer{
	
background-color: white;
box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
border-radius: 7px;
display: flex;
    align-content: center;
    margin-bottom: 30px;


}

.divilist{
float: left;
margin:17px 17px 17px 17px;

}

.listavatar{
height: 60px;
width: 60px;
border-radius: 100%;
}

.listcontenido div{
	width: 310px;
    height: 24px;
    margin-top:4px;


}

.pajarot{
	width:17px;
	float:left;
}

.lname {

    font-weight: 600;

}


.ltwit{
    font-size: 13px;
    color: #1da1f2;
}
