.listcontainer{
	
background-color: white;
box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
border-radius: 7px;
display: flex;
    align-content: center;
    margin-bottom: 30px;


}

.divilist{
float: left;
margin:17px 17px 17px 17px;

}

.listavatar{
height: 60px;
width: 60px;
border-radius: 100%;
}

.listcontenido div{
	width: 310px;
    height: 24px;
    margin-top:4px;


}

.pajarot{
	width:17px;
	float:left;
}

.lname {

    font-weight: 600;

}


.ltwit{
    font-size: 13px;
    color: #1da1f2;
}