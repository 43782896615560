.hero2 {
  width: 100%;
  padding: 2rem 0;
  background: url("../../images/stars.svg"), #1b1b25;
  background-repeat: repeat;
  margin-bottom: 1rem;
  color: #fff;
  display: flex;
align-content: center;
justify-content: center;
}


.img-fluid{
	max-height :100px;

}